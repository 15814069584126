import { activeLocale, transl } from "../useTranslation"

function byLocale(a: any, b: any, predicate: ((p: any) => string)) {
    const aValue = predicate(a);
    const bValue = predicate(b);

    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
}

function byTransl(a: any, b: any, predicate?: ((p: any) => string)) {
    const aValue = predicate ? predicate(a) : a;
    const bValue = predicate ? predicate(b) : b;

    return new Intl.Collator(activeLocale.value).compare(
        transl(aValue),
        transl(bValue)
    )
}

export {
    byLocale,
    byTransl
}